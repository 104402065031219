import axios from "axios";

// Actions
import {
  getViewProof,
  getViewProofForAdminOrder,
  getViewProofForOrder,
} from '../redux/actions/template-builder';
import { success, failure } from '../redux/actions/snackbar-actions';

export const multiPageTemplates = [
  'Postcards',
  'Tri-Fold Self-Mailers',
  'Bi-Fold Self-Mailers',
];

export const getFileAsBlob = async (url, returnType = 'json') => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    return returnType === 'json'
      ? blobToJSON(response.data)
      : blobToString(response.data);
  } catch (error) {
    throw error; // Optionally rethrow the error for further handling
  }
};

const blobToJSON = (jsonBlob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define a callback function for when the FileReader finishes reading
    reader.onload = function () {
      try {
        // Parse the result as JSON
        const parsedData = JSON.parse(reader.result);

        // Resolve the promise with the parsed JSON data
        resolve(parsedData);
      } catch (error) {
        // Reject the promise with the error
        reject(error);
      }
    };

    // Define a callback function for when there is an error reading the Blob
    reader.onerror = function (error) {
      // Reject the promise with the error
      reject(error);
    };

    // Start reading the Blob as text
    reader.readAsText(jsonBlob);
  });
};

export const extractVariablesFromHtml = (html) => {
  const placeholderRegex =
    /\{\{\s*([A-Za-z_][A-Za-z0-9_]*(?:\.[A-Za-z_][A-Za-z0-9_]*)*)\s*\}\}/g;

  // Extract matches from the HTML string
  const matches = html.match(placeholderRegex);

  // Log the matches
  if (!matches) return [];
  var regex = /{{(.*?)}}/;
  return matches.map((item) => {
    const matchArray = item.match(regex);
    if (matchArray && matchArray[1]) {
      const [fullMatch, variable] = matchArray;
      return {
        key: fullMatch,
        value: variable,
        defaultValue: variable,
      };
    } else {
      return {
        key: item,
        value: item,
        defaultValue: item,
      };
    }
  });
};

export const createViewProof = (title, id) => async (dispatch) => {
  const response = await getViewProof(id);
  const binaryData = atob(response.data.data.base64Pdf);

  if (response.status === 200) {
    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);
    downloadPDF(title, url);
    dispatch(success('Download Proof generated successfully'));
  } else {
    dispatch(failure(response.data.message));
  }
};

export const createViewProofForOrder = (title, payload, component = 'order') => async (dispatch) => {
  const response = component === 'admin-order' ? await getViewProofForAdminOrder(payload) : await getViewProofForOrder(payload);
  const binaryData = atob(response.data.data.base64);

  if (response.status === 200) {
    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);
    downloadPDF(title, url);
    dispatch(success('Download Proof generated successfully'));
  } else {
    dispatch(failure(response.data.message));
  }
};

export const downloadPDF = (title, url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = title + '.pdf';

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
import React, { useState, useEffect, useRef } from "react";

//moment
import moment from "moment";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import {
  fetchOrders,
  fetchFiltersLabels,
  orderBulkActions,
} from "../../redux/actions/orders-actions";
import { CLEAR_ORDERS_FILTERS } from "../../redux/actions/action-types";
import { failure, success } from "../../redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "../../utils/message";
import { DEFAULT_PAGINATION } from "../../utils/constants";
import { dateFormat } from "../../utils/date-format";

// Component
import Table from "../Orders/OrderListing";
import AdvanceFilters from "./AdvanceFilters";
import NoOrdersScreen from "./NoOrderScreen";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";

// MUI Components
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

// icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

// images
import ActiveFilter from "../../assets/images/contacts/filter-2.svg";
import InactiveFilter from "../../assets/images/contacts/filter.svg";
import Search from "../../assets/images/contacts/contact-search.svg";
import Create from "../../assets/images/templates/add-icon.svg";
import PayAndProcessIcon from "../../assets/images/orders/processOrder.png";
import CancelOrderIcon from "../../assets/images/orders/cancelOrder.svg";

// styles
import "./styles.scss";

const Orders = () => {
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [action, setAction] = useState({
    message: "",
    currentAction: null,
    successButtonName: "",
  });

  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalOrdersInDB = useSelector(
    (state) => state.ordersReducers.totalOrdersInDB
  );

  const applyFilters = useSelector(
    (state) => state.ordersReducers.applyFilters
  );
  const filters = useSelector((state) => state.ordersReducers.filters);
  const filterSize = useSelector((state) => state.ordersReducers.filterSize);

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getOrders();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getOrders = () => {
    let payload = {
      pagination,
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchOrders(payload));
  };

  const cancelOrders = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "cancel",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await orderBulkActions(payload);
      getOrders();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setLoading(false);
      setActionModal(false);
    }
  };

  const payAndProcessOrders = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "pay and process",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await orderBulkActions(payload);
      getOrders();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.message));
    } finally {
      setLoading(false);
      setActionModal(false);
    }
  };

  selectedRows[0] === -1 || selectedRows.length > 1
    ? MESSAGES.CONTACTS.MULTI_DELETE_HEADER
    : MESSAGES.CONTACTS.SINGLE_DELETE_HEADER;

  const handleActions = async (type) => {
    if (type === "cancel") {
      setAction({
        message:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MULTI_MESSAGE
            : MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MESSAGE,
        currentAction: "cancel",
        successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUCCESS_BUTTON,
        subMessage:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MULTI_SUB_MESSAGE
            : MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUB_MESSAGE,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.HEADING,
        icon: CancelOrderIcon,
      });
    } else if (type === "payAndProcess") {
      const currentDate = moment();
      setAction({
        message: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MESSAGE,
        currentAction: "payAndProcess",
        successButtonName:
          MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUCCESS_BUTTON,
        subMessage:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? `${
                MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MULTI_SUB_MESSAGE
              } ${dateFormat(currentDate, true)}`
            : `${
                MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUB_MESSAGE
              } ${dateFormat(currentDate, true)}`,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.HEADING,
        icon: PayAndProcessIcon,
      });
    }
    setActionModal(true);
  };

  useEffect(() => {
    setIsMounted(true);
    getOrders();
    dispatch(fetchFiltersLabels());
    return () => {
      dispatch({ type: CLEAR_ORDERS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getOrders();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getOrders();
    }
  }, [search]);

  useEffect(() => {
    if (isMounted) {
      let payload = {
        DEFAULT_PAGINATION,
        search: search.length ? search : null,
      };
      dispatch(fetchOrders(payload));
    }
  }, [currentMode]);

  const noOrdersInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoOrdersScreen />
      </Container>
    );
  };

  if (totalOrdersInDB < 1 && totalOrdersInDB !== null) {
    return noOrdersInDb();
  }

  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Grid container>
        <Grid
          item
          lg={7}
          md={selectedRows.length > 0 ? 12 : 7}
          sm={12}
          xs={12}
          className="searchBtnWrapper"
        >
          <Box className="searchWrapper">
            <Input
              className={`searchInput ${searchApplied ? "searchApplied" : ""}`}
              type="text"
              value={search}
              onChange={(e) => {
                if (e.target.value.length > 9) return;
                setSearch(e.target.value.replace(/\s/g, "").replace(/\D/g, ""));
              }}
              onKeyDown={searchKeyDown}
              placeholder={MESSAGES.ORDERS.SEARCH_PLACE_HOLDER}
              endAdornment={
                <InputAdornment position="end" className="searchInputAdornment">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    className="searchIcon"
                    onClick={handleSearch}
                  >
                    <img src={Search} alt="search" />
                  </IconButton>
                  {searchApplied && (
                    <HighlightOffIcon
                      onClick={() => {
                        setSearch("");
                      }}
                      className="clearSerach"
                    />
                  )}
                </InputAdornment>
              }
            />
            <Box className="filterBtnWrapper">
              <img
                onClick={() => {
                  setOpenAdvanceFilters(true);
                }}
                className={
                  filterSize > 0
                    ? "filterImage activeFilter"
                    : "filterImage noFilter"
                }
                src={filterSize > 0 ? ActiveFilter : InactiveFilter}
                alt="Filter"
              />
              {filterSize > 0 && (
                <Typography className="filtersCounter">{filterSize}</Typography>
              )}
            </Box>
            {filterSize > 0 && (
              <Button
                onClick={() => {
                  childRef.current.clearFilters();
                }}
                className="clearFilterBtn"
              >
                {MESSAGES.ORDERS.CLEAR_FILTER_BTN_TEXT}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={5}
          md={selectedRows.length > 0 ? 12 : 5}
          sm={12}
          xs={12}
          sx={{
            marginTop: { md: "0px", sm: "20px", xs: "20px" },
            alignItems: "flex-start",
          }}
        >
          {/* Create Button */}
          <Box
            className="actionsBtnWrapper"
            sx={{
              "@media (max-width:1199px)": {
                marginTop: selectedRows.length > 0 && "20px",
              },
            }}
          >
            {selectedRows.length > 0 && (
              <>
                <Button onClick={() => handleActions("cancel")}>
                  <CloseIcon />
                  {MESSAGES.ORDERS.CANCEL_ORDER_BTN_TEXT}
                </Button>
                <Button onClick={() => handleActions("payAndProcess")}>
                  <DoneIcon />
                  {MESSAGES.ORDERS.PAY_AND_PROCESS_BTN_TEXT}
                </Button>
              </>
            )}
            <Button
              className="templateCreateBtn"
              onClick={() => navigate("/orders/create-order")}
            >
              <img src={Create} alt="actions" />
              <Typography>Create</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Table
        pagination={pagination}
        updatePagination={setPagination}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        searchApplied={searchApplied}
      />
      <AdvanceFilters
        open={openAdvanceFilters}
        handleCloseFilter={() => {
          setOpenAdvanceFilters(false);
        }}
        ref={childRef}
        updatePagination={setPagination}
      />
      {actionModal && (
        <ConfirmDialog
          dialog={action}
          open={actionModal}
          loading={loading}
          handleClose={() => setActionModal(false)}
          handleOk={
            action.currentAction === "cancel"
              ? cancelOrders
              : action.currentAction === "payAndProcess"
              ? payAndProcessOrders
              : undefined
          }
        />
      )}
    </Container>
  );
};

export default Orders;

import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { createContact } from "../../../../redux/actions/contacts-actions.js";

//Utils
import { MESSAGES } from "../../../../utils/message.js";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";
import { EMAIL_REGEX } from "../../../../utils/constants.js";

// Components
import ModalInput from "./ModalInput/index.jsx";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Grid, CircularProgress } from "@mui/material";

// image
import Close from "../../../../assets/images/modal/modal-cross.jsx";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const NewContact = (props) => {
  const { handleClose, open, setSelectedContact } = props;
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .test({
        test: function (value) {
          const { companyName } = this.parent;
          return !!value || !!companyName;
        },
        message: MESSAGES.CONTACTS.CREATE.FIRST_NAME_OR_COMPANY_REQUIRED,
      })
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_LIMIT)
      .notRequired(),
    companyName: yup
      .string()
      .max(100, MESSAGES.CONTACTS.CREATE.COMPANY_MAX_LENGTH)
      .test({
        test: function (value) {
          const { firstName } = this.parent;
          return !!value || !!firstName;
        },
        message: MESSAGES.CONTACTS.CREATE.FIRST_NAME_OR_COMPANY_REQUIRED,
      })
      .notRequired(),
    lastName: yup
      .string()
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_LIMIT),
    address1: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.ADDRESS_1__REQUIRED),
    address2: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_2_LIMIT),
    city: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.CITY_REQUIRED),
    state: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.STATE_REQUIRED),
    zip: yup
      .string()
      .max(10, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.ZIP_REQUIRED),

    email: yup.string().matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL),

    // property address fields
    propertyAddress: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LIMIT),
    propertyState: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LIMIT),
    propertyCity: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LIMIT),
    propertyZip: yup
      .string()
      .max(10, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phoneNo: "",
      email: "",
      propertyAddress: "",
      propertyState: "",
      propertyCity: "",
      propertyZip: "",
    },
    validationSchema: validationSchema,
  });

  const tags = useSelector((state) => state.contactsReducers.labels);

  // Sort the tags alphabetically
  const sortedTags = [...tags].sort((a, b) => a?.title.localeCompare(b?.title));

  const tagsOptions = sortedTags?.map((tag) => {
    return {
      id: tag.id,
      title: tag.title,
    };
  });

  const onClose = () => {
    handleClose();
    formik.resetForm();
    resetValidation();
    setSelectedTags([]);
    // Reset all formik.touched values to false
    Object.keys(formik.initialValues).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, false);
    });
  };

  const create = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = { ...formik.values };
      selectedTags?.length ? (payload.tags = selectedTags) : undefined;
      payload?.email?.length ? payload?.email : (payload.email = undefined);

      payload.propertyAddress = payload?.propertyAddress?.trim() || undefined;
      payload.propertyState = payload?.propertyState?.trim() || undefined;
      payload.propertyCity = payload?.propertyCity?.trim() || undefined;
      payload.propertyZip = payload?.propertyZip?.trim() || undefined;

      setLoading(true);
      const response = await dispatch(createContact(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        const createdContact = response.data.data;
        const selectedContact = {
          id: createdContact.id,
          label: `${createdContact.fullName}, ${createdContact.address1} ${
            createdContact?.address2 || ""
          } ${createdContact?.city || ""}, ${createdContact?.state || ""} ${
            createdContact?.zip || ""
          }`,
        };
        setSelectedContact(selectedContact);
        onClose();
      } else {
        dispatch(failure(response.data.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="orderContactModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent className="orderContactModalContent" dividers>
          <form className="updateFormWrapper" onSubmit={create}>
            <Box className="orderContactHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ORDERS.CREATE.CONTACTS.CREATE}
              </Typography>
            </Box>
            <Box className="orderContent">
              {/* first row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.FIRST_NAME_LABEL}
                    type="text"
                    name="firstName"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.firstName}
                    updateValue={formik.handleChange}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.LAST_NAME_LABEL}
                    name="lastName"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.lastName}
                  />
                </Grid>
              </Grid>
              {/* second row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.COMPANY_LABEL}
                    name="companyName"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.companyName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ADDRESS_1_LABEL}
                    name="address1"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.address1}
                  />
                </Grid>
              </Grid>
              {/* third row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ADDRESS_2_LABEL}
                    name="address2"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.address2}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.CITY_LABEL}
                    name="city"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.city}
                  />
                </Grid>
              </Grid>
              {/* fourth row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.STATE_LABEL}
                    name="state"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.state}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ZIP_LABEL}
                    name="zip"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.zip}
                  />
                </Grid>
              </Grid>
              {/* fifth row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.PHONE_LABEL}
                    name="phoneNo"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.phoneNo}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.EMAIL_LABEL}
                    name="email"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.email}
                  />
                </Grid>
              </Grid>

              {isPropertyAddressEnabled && (
                <>
                  {/* sixth row */}
                  <Grid container spacing={2} className="gridCustomize">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_ADDRESS}
                        name="propertyAddress"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyAddress}
                        isCreate={true}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_CITY}
                        name="propertyCity"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyCity}
                        isCreate={true}
                      />
                    </Grid>
                  </Grid>
                  {/* seventh row */}
                  <Grid container spacing={2} className="gridCustomize">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_STATE}
                        name="propertyState"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyState}
                        isCreate={true}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_ZIP}
                        name="propertyZip"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyZip}
                        isCreate={true}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {/* fifth row */}
              <Grid container spacing={2} className="gridCustomize">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.TAGS_LABEL}
                    name="tags"
                    isMultiSelect={true}
                    options={tagsOptions}
                    value={selectedTags}
                    setValue={setSelectedTags}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="orderContactModalBtns">
              <Button onClick={onClose}>
                {MESSAGES.ORDERS.CREATE.CONTACTS.CANCEL_BUTTON}
              </Button>
              <Button
                type="submit"
                className="btnSave"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ORDERS.CREATE.CONTACTS.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default NewContact;

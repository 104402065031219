import moment from 'moment';

export const dateFormat = (inputDateString, dateOnly = false) => {
  const inputDate = new Date(inputDateString);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: dateOnly ? undefined : '2-digit',
    minute: dateOnly ? undefined : '2-digit',
    hour12: dateOnly ? false : true,
  };

  return inputDateString ? inputDate.toLocaleString('en-US', options) : '';
};

export const dateProtector = (update) => {
  if (Array.isArray(update)) {
    if (update[0] !== null && update[1] !== null) {
      update[0].setHours(12);
      update[1].setHours(12);
    }
  } else {
    update.setHours(12);
  }
  return update;
}
export const getTimeOnly = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  return inputDateString ? inputDate.toLocaleString('en-US', options) : null;
}


export const giveDaysUptilNow = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const now = new Date();
  const diff = now - inputDate;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
}

export function doesItFallInCancellationPeriod(deliveryDate) {
  const currentDate = moment();
  const scheduledDate = moment(deliveryDate);
  const isScheduledDayArrived = scheduledDate.isBefore(currentDate);
  const dayOfWeek = currentDate.day(); 
  const allowedDays = [1, 3, 5];
  if (moment(scheduledDate).isSame(currentDate, 'day')) {
    return true
  }
  return (!isScheduledDayArrived || (isScheduledDayArrived && !allowedDays.includes(dayOfWeek)))

}

export const isValidDate = (dateString) => {
  let dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})( (\d{1,2}):(\d{2}):(\d{2}))?$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  let [, month, day, year, time] = dateString.match(dateRegex);

  let date = new Date(year, month - 1, day);
  if (
    date.getFullYear() != year ||
    date.getMonth() + 1 != month ||
    date.getDate() != day ||
    date.getFullYear() < 1000 ||
    date.getFullYear() > 9999
  ) {
    return false;
  }

  if (time) {
    var [hours, minutes, seconds] = time.split(":");
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      return false;
    }
  }

  return true;
}

export const dateFromString = (dateString) => {
  let dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})( (\d{1,2}):(\d{2}):(\d{2}))?$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  let [, month, day, year, time] = dateString.match(dateRegex);

  let date = new Date(year, month - 1, day);
  if (
    date.getFullYear() != year ||
    date.getMonth() + 1 != month ||
    date.getDate() != day ||
    date.getFullYear() < 1000 ||
    date.getFullYear() > 9999
  ) {
    return false;
  }

  if (time) {
    var [hours, minutes, seconds] = time.split(":");
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      return false;
    }
  }

  return date;
}

export const handleRawChangeForDatePicker = (e, setState) => {

  if (e.target.value) {
    const dates = e.target.value.split(' - ').map(d => {
      return isValidDate(d) ? d : null;
    });

    if (dates[0] && dates[1]) {
      const datesToUpdate = dates.map(d => dateFromString(d));
      dateProtector(datesToUpdate)
      setState(datesToUpdate);
    }

  }
}

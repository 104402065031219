import React, { useEffect, useState } from "react";

//hooks
import { useNavigate } from "react-router-dom";

// Utils
import { MESSAGES } from "../../../utils/message";

//hooks
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// images
import warning from "../../../assets/images/user-table/warning.svg";
import cross from "../../../assets/images/modal/crossIcon.svg";

// styles
import "./styles.scss";

import { setLimitModal } from "../../../redux/actions/dashboard-actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const LimitModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [heading, setHeading] = useState(`You've reached your limit`);

  const openModal = useSelector((state) => state.dashboardReducers.limitModal);
  const dynamicTitle = useSelector(
    (state) => state.dashboardReducers.limitModalTitle
  );
  const dynamicSubHeading = useSelector(
    (state) => state.dashboardReducers.limitModalHeading
  );
  const dynamicHeading = useSelector(
    (state) => state.dashboardReducers.limitModalMessage
  );

  useEffect(() => {
    if (location.pathname.includes("users")) {
      setHeading("users");
    } else if (location.pathname.includes("template")) {
      setHeading("templates");
    } else if (location.pathname.includes("custom-fields")) {
      setHeading("custom fields");
    } else if (location.pathname.includes("tags")) {
      setHeading("tags");
    } else if (location.pathname.includes("api-keys")) {
      setHeading("integrations");
    } else if (location.pathname.includes("webhooks")) {
      setHeading("webhooks");
    } else if (location.pathname.includes("contacts")) {
      setHeading("contacts");
    }
  }, [openModal]);

  const handleCloseModal = () => {
    dispatch(setLimitModal(false));
  };

  const handleViewPlans = () => {
    dispatch(setLimitModal(false));
    navigate("/account/plan");
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="limitModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={cross} alt="close" />
        </IconButton>
        <DialogContent className="limitModalContent" dividers>
          <img src={warning} alt="export" />
          <Box className="limitHeader">
            <Typography gutterBottom variant="h4">
              {dynamicTitle || MESSAGES.TEMPLATE.LIMIT_MODAL.TITLE}
            </Typography>
            <Typography gutterBottom className="limitPara">
              {dynamicSubHeading || `You've reached your ${heading} limit!`}
              <br />
              {dynamicHeading ||
                MESSAGES.ACCOUNT.USERS.LIMIT_MODAL.DEFAULT_MODAL_SUB_HEADING}
            </Typography>
          </Box>
          <Box className="limitModalBtns">
            <Button onClick={handleCloseModal}>
              {MESSAGES.TEMPLATE.LIMIT_MODAL.CANCEL_BUTTON}
            </Button>
            <Button onClick={() => handleViewPlans()}>
              {MESSAGES.TEMPLATE.LIMIT_MODAL.SUBMIT_BUTTON}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default LimitModal;

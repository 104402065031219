import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Date Range Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { createApiKey } from "../../../../redux/actions/apiKeys-actions";
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { fetchSources } from "../../../../redux/actions/sources-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";
import { dateProtector } from "../../../../utils/date-format";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Custom Components
import MultiSelect from "../../../General/MultiSelect";

// icons
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CreateApiModal = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [formSelect, setFormSelect] = useState({
    source: "",
  });
  const [sourceOptions, setSourceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { openModal, handleCloseModal } = props;

  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(MESSAGES.SETTINGS.API_KEYS.CREATE.NAME_REQUIRED)
      .min(1, MESSAGES.SETTINGS.API_KEYS.CREATE.NAME_REQUIRED)
      .max(255, MESSAGES.SETTINGS.API_KEYS.CREATE.NAME_LESS_255),
    source: yup
      .string()
      .required(MESSAGES.SETTINGS.API_KEYS.CREATE.SOURCE_REQUIRED),
    ghlApiKey: yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      source: "",
      ghlApiKey: "",
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
    setStartDate(null);
    setFormSelect({
      source: "",
    });
    formik.setTouched({});
  };

  const handleFormSelect = (field, newValue) => {
    formik.setFieldValue(field, newValue[0]);
    setFormSelect((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const createNewApi = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        name: formik.values.name.trimStart(),
        source: formSelect?.source[0],
        crmApiKey: formik.values.ghlApiKey,
        expiredAt: startDate?.toISOString()?.slice(0, 10),
        isSecretRequired: false,
      };
      const response = await dispatch(createApiKey(payload));
      if (
        response.data.message ==
        MESSAGES.SETTINGS.API_KEYS.RESPONSE_ALREADY_EXIST
      ) {
        formik.setErrors({
          name: MESSAGES.SETTINGS.API_KEYS.NAME_ALREADY_EXIST,
          source: MESSAGES.SETTINGS.API_KEYS.SOURCE_ALREADY_EXIST,
        });
        formik.touched["name"] = true;
        formik.touched["source"] = true;
      } else if (
        response.data.message == MESSAGES.SETTINGS.API_KEYS.GHL_API_KEY_INVALID
      ) {
        formik.setErrors({
          ghlApiKey: MESSAGES.SETTINGS.API_KEYS.GHL_API_KEY_INVALID,
        });
        formik.touched["ghlApiKey"] = true;
      } else {
        dispatch(success(response.data.message));
        onClose();
      }
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const fetchSourcesData = async () => {
    const response = await dispatch(fetchSources());
    if (response?.status === 200) {
      const mappedOptions = response?.data?.data?.map((item) => ({
        id: item.id,
        title: item.name,
      }));
      setSourceOptions(mappedOptions);
    }
  };

  useEffect(() => {
    fetchSourcesData();
  }, []);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="createApiModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={crossicon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.API_KEYS.CREATE.TITLE}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={createNewApi}>
            {/* Name of API Key */}
            <Box className="editNameInputsWrapper">
              <InputLabel htmlFor="first-name" className="inputLabel">
                {MESSAGES.SETTINGS.API_KEYS.CREATE.NAME_LABEL}
              </InputLabel>
              <TextField
                placeholder="Name"
                fullWidth
                name="name"
                autoComplete="off"
                onChange={formik.handleChange}
                className={
                  formik.touched.name && formik.errors.name
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.name.trimStart()}
              />
              {formik.touched.name && formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            {/* Source of API Key */}
            <Box className="editNameInputsWrapper">
              <InputLabel htmlFor="first-name" className="inputLabel">
                {MESSAGES.SETTINGS.API_KEYS.CREATE.SOURCE_LABEL}
              </InputLabel>
              <MultiSelect
                options={sourceOptions}
                name="source"
                selectedValue={formSelect?.source}
                setSelectedValue={(newValue) =>
                  handleFormSelect("source", newValue)
                }
                placeHolderText="Source"
                component="API Key"
                multiple={false}
                error={formik.touched.source && formik.errors.source}
              />
              {formik.touched.source && formik.errors.source ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.source}
                </Typography>
              ) : null}
            </Box>
            {formik.values.source.includes("GoHighLevel") && (
              /* GHL API Key for Webhook (Optional) */
              <Box className="editNameInputsWrapper">
                <InputLabel htmlFor="first-name" className="inputLabel">
                  {MESSAGES.SETTINGS.API_KEYS.CREATE.GHL_API_KEY_LABEL}
                </InputLabel>
                <TextField
                  placeholder="GHL API Key"
                  fullWidth
                  name="ghlApiKey"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  className={
                    formik.errors.ghlApiKey && formik.touched.ghlApiKey
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.ghlApiKey}
                />
                {formik.touched.ghlApiKey && formik.errors.ghlApiKey ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.ghlApiKey}
                  </Typography>
                ) : null}
              </Box>
            )}
            {/* Expiry Date of API Key */}
            <Box className="mailedDate editNameInputsWrapper">
              <Box className="selectDate" sx={{ maxWidth: "100% !important" }}>
                <InputLabel className="dateLabel">
                  {MESSAGES.SETTINGS.API_KEYS.CREATE.DATE_LABEL}
                </InputLabel>
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                  }}
                />
                <DatePicker
                  toggleCalendarOnIconClick={true}
                  selected={startDate}
                  minDate={addDays(new Date(), 1)}
                  onChange={(update) => {
                    dateProtector(update);
                    setStartDate(update);
                  }}
                  onFocus={() => setIsDatepickerOpen(true)}
                  onBlur={() => setIsDatepickerOpen(false)}
                  placeholderText={
                    MESSAGES.SETTINGS.API_KEYS.CREATE.DATE_PLACEHOLDER
                  }
                />
              </Box>
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.SETTINGS.API_KEYS.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={() => validateForm(formik)}
                disabled={loading && true}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.SETTINGS.API_KEYS.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CreateApiModal;

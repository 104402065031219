import { useEffect } from "react";

// Third Party Integrations
import TemplateBuilderSDK from "@openlettermarketing/olc-react-sdk";

// Hooks
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  createTemplate,
  getTemplatesByTab,
  getOneTemplate,
  updateTemplate,
} from "../../redux/actions/template-builder";
import { fetchCustomFields } from "../../redux/actions/customFields-actions";
import { failure, success } from "../../redux/actions/snackbar-actions";

// Utils
import { getUserToken } from "../../utils/auth";
import { MESSAGES } from "../../utils/message";

// Styles
import "@blueprintjs/core/lib/css/blueprint.css";
import "./styles.scss";


const TemplateBuilder = () => {
  let { id } = useParams();
  let templateBuilderInstance = null;
  const isSandboxMode =
    import.meta.env.VITE_APP_ENV !== "production" ? true : false;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const redirectToOlc = () => {
    navigate("/templates");
  };

  const onCreate = async (formData) => {
    try {
      const { data, status } = await createTemplate(formData);
      if (status === 200) {
        dispatch(success(MESSAGES.TEMPLATE.TEMPLATE_BUILDER.CREATED));
        return true;
      }
    } catch (error) {
      const errorMessage =
        error?.data?.data?.errors?.title?.[0] ||
        error?.data?.data?.errors?.[0]?.msg ||
        error?.data?.message ||
        "Internal Server Error";
      dispatch(failure(errorMessage));
      return false;
    }
  };

  const onUpdate = async (formData) => {
    try {
      const { data, status } = await updateTemplate(id, formData);
      if (status === 200) {
        dispatch(success(MESSAGES.TEMPLATE.TEMPLATE_BUILDER.UPDATED));
        return true;
      }
    } catch (error) {
      const errorMessage =
        error?.data?.data?.errors?.title?.[0] ||
        error?.data?.data?.errors?.[0]?.msg ||
        error?.data?.message ||
        "Internal Server Error";
      dispatch(failure(errorMessage));
      return false;
    }
  };

  const setEditingTemplate = async () => {
    const template = await getOneTemplate(id);
    if (template?.data?.data) {
      createTemplateBuilder(template?.data?.data);
    } else {
      redirectToOlc();
    }
  };

  const getAllTemplatesByTab = async (payload) => {
    try {
      const tempates = await getTemplatesByTab(payload);
      if (tempates.status === 200) {
        return tempates?.data?.data;
      } else {
        return null;
      }
    } catch (error) {
      dispatch(failure(error?.data?.message || "Internal Server Error"));
      return null;
    }
  };

  const getTemplateById = async (id) => {
    try {
      const tempate = await getOneTemplate(id);
      if (tempate.status === 200) {
        return tempate?.data?.data;
      }
    } catch (error) {
      dispatch(failure(error?.data?.message || "Internal Server Error"));
      return false;
    }
  };

  const getCustomFields = async () => {
    const customFields = await dispatch(
      fetchCustomFields({
        pageSize: 500,
      })
    );
    if (customFields?.data?.data?.rows) {
      return customFields?.data?.data?.rows;
    } else {
      return [];
    }
  };

  const destroyTemplateBuilder = () => {
    templateBuilderInstance?.destroy();
    templateBuilderInstance = null;
  };

  const createTemplateBuilder = (editingTemplateData = null) => {
    templateBuilderInstance = new TemplateBuilderSDK({
      container: document.getElementById("template-builder-container"),
      secretKey: import.meta.env.VITE_APP_PLOTNO_API_KEY,
      publicApiKey: getUserToken(),
      platformName: "OLC",
      allowPropertyFields: isPropertyAddressEnabled,
      allowSenderFields: true,
      templateGalleryModal: true,
      sandbox: isSandboxMode,
      olcTemplate: editingTemplateData,
      onReturnAndNavigate: redirectToOlc,
      onGetCustomFields: getCustomFields,
      onGetTemplates: getAllTemplatesByTab,
      onGetOneTemplate: getTemplateById,
      onSubmit: editingTemplateData ? onUpdate : onCreate,
    });
  };

  useEffect(() => {
    if (id) {
      setEditingTemplate();
    } else {
      createTemplateBuilder();
    }

    return () => {
      destroyTemplateBuilder();
    };
  }, []);

  return (
    <>
      <div id="template-builder-container" />
    </>
  );
};

export default TemplateBuilder;

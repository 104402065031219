import React, { useEffect, useRef, useState } from "react";

// Hooks
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  fetchAdminBatchFilters,
  fetchAdminBatches,
} from "../../../redux/actions/admin-batch-actions";
import { CLEAR_ADMIN_BATCHES_FILTERS } from "../../../redux/actions/action-types";

//Utils
import { DEFAULT_PAGINATION } from "../../../utils/constants";
import { MESSAGES } from "../../../utils/message";
import {
  balanceFormatter,
  numberWithComma,
} from "../../../utils/helperFunctions";

// Components
import Table from "./Table";
import AdvanceFilters from "./Filters";
import NoBatchesScreen from "./NoCategoriesScreen";

// MUI Table
import {
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Icons
import Search from "../../../assets/images/contacts/contact-search.svg";
import ActiveFilter from "../../../assets/images/contacts/filter-2.svg";
import InactiveFilter from "../../../assets/images/contacts/filter.svg";

// Styles
import "./styles.scss";

const AdminBatch = () => {
  const { batchId } = useParams();
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);

  const childRef = useRef();
  const dispatch = useDispatch();

  const applyFilters = useSelector(
    (state) => state.adminBatchesReducers.applyFilters
  );

  const filters = useSelector((state) => state.adminBatchesReducers.filters);
  const filterSize = useSelector(
    (state) => state.adminBatchesReducers.filterSize
  );

  const totalQuantity = useSelector(
    (state) => state.adminBatchesReducers.totalQuantity
  );

  const totalRevenue = useSelector(
    (state) => state.adminBatchesReducers.totalRevenue
  );

  const totalBatchesInDB = useSelector((state) => state.adminBatchesReducers.totalBatchesInDB);

  const statsCard = [
    { title: "Total Quantity", value: numberWithComma(totalQuantity) },
    { title: "Total Revenue", value: balanceFormatter(totalRevenue) },
  ];

  const handleSearch = () => {
    if (search) {
      search.trim();
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminBatches();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getAdminBatches = (batchId) => {
    let payload = {
      pagination,
      search: batchId ? batchId : search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchAdminBatches(payload));
  };

  useEffect(() => {
    setIsMounted(true);
    batchId && setSearch(batchId);
    batchId && setSearchApplied(true);
    batchId ? getAdminBatches(batchId) : getAdminBatches();
    dispatch(fetchAdminBatchFilters());
    return () => {
      dispatch({ type: CLEAR_ADMIN_BATCHES_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getAdminBatches();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminBatches();
    }
  }, [search]);

  const noBatchesInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoBatchesScreen />
      </Container>
    );
  };

  if(totalBatchesInDB === 0 && totalBatchesInDB !== null) 
  {
    return  noBatchesInDb();
  }

  return (
    <>
      <Container maxWidth="xxl">
        <div className="adminOrderMain">
          <Grid container>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="searchBtnWrapper"
            >
              <Box className="searchWrapper">
                <Input
                  className={`searchInput ${
                    searchApplied ? "searchApplied" : ""
                  }`}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    if (e.target.value.length > 9) return;
                    setSearch(
                      e.target.value.replace(/\s/g, "").replace(/\D/g, "")
                    );
                  }}
                  onKeyDown={searchKeyDown}
                  placeholder={
                    MESSAGES.ADMIN_DASHBOARD.BATCHES.SEARCH_PLACE_HOLDER
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className="searchInputAdornment"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        className="searchIcon"
                        onClick={handleSearch}
                      >
                        <img src={Search} alt="search" />
                      </IconButton>
                      {searchApplied && (
                        <HighlightOffIcon
                          onClick={() => {
                            setSearch("");
                          }}
                          className="clearSerach"
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    pattern: "[0-9]*",
                    title: "Only numbers are allowed",
                  }}
                />
                <Box className="filterBtnWrapper">
                  <img
                    onClick={() => {
                      setOpenAdvanceFilters(true);
                    }}
                    className={
                      filterSize > 0
                        ? "filterImage activeFilter"
                        : "filterImage noFilter"
                    }
                    src={filterSize > 0 ? ActiveFilter : InactiveFilter}
                    alt="Filter"
                  />
                  {filterSize > 0 && (
                    <Typography className="filtersCounter">
                      {filterSize}
                    </Typography>
                  )}
                </Box>
                {filterSize > 0 && (
                  <Button
                    onClick={() => {
                      childRef.current.clearFilters();
                    }}
                    className="clearFilterBtn"
                  >
                    {MESSAGES.ADMIN_DASHBOARD.BATCHES.CLEAR_FILTER_BTN_TEXT}
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="searchBtnWrapper outer"
            >
              {statsCard.map((item, index) => (
                <Box key={index} className="infoBox">
                  <span>{item.title}</span>
                  <p>{item.value}</p>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Table pagination={pagination} updatePagination={setPagination} searchApplied={searchApplied}/>
          <AdvanceFilters
            open={openAdvanceFilters}
            handleCloseFilter={() => setOpenAdvanceFilters(false)}
            ref={childRef}
            updatePagination={setPagination}
          />
        </div>
      </Container>
    </>
  );
};

export default AdminBatch;

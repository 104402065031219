import React from "react";

// MUI Components
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";

// Styles
import "./styles.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = (props) => {
  const {
    options,
    selectedValue,
    setSelectedValue,
    component,
    placeholder,
    mailedStatus,
    simpleSelect = false,
    noOptionText,
  } = props;
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <FormControl className="checkboxSelectWrapper" sx={{ m: 1, width: 300 }}>
      <Select
        id="demo-multiple-checkbox"
        multiple={!simpleSelect}
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography sx={{ opacity: "0.5", fontSize: "14px" }}>
                {placeholder}
              </Typography>
            );
          }
          return component === "tag" ? "" : selected.join(", ");
        }}
        MenuProps={{
          ...MenuProps,
          classes: {
            paper: component === "editUser" && "editUserMenuPaper",
          },
          MenuListProps: {
            onFocus: handleMenuOpen,
            onBlur: handleMenuClose,
          },
        }}
        IconComponent={() => (
          <KeyboardArrowDownIcon
            sx={{
              color: menuOpen ? "#ED5C2F" : "inherit",
              position: "absolute",
              right: "13px",
            }}
          />
        )}
        className="statuSelect"
        sx={{
          "&.statuSelect .MuiSelect-select": {
            maxWidth:
              component === "tag" || component === "contactTags" ? "100% !important" : "500px !important",
            padding: mailedStatus
              ? "14px 35px 14px 22px !important"
              : "12px 35px 12px 22px !important",
            "@media (max-width: 900px)": {
              maxWidth: "100% !important"
            }
          },
        }}
      >
        {options.length > 0 ? (
          options?.map(({ id, title }) => (
            <MenuItem key={id} value={component === "tag" ? id : title}>
              {!simpleSelect && (
                <Checkbox
                  checked={
                    selectedValue.indexOf(component === "tag" ? id : title) > -1
                  }
                />
              )}
              <ListItemText primary={title} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled className="noOptionsText">
            <Typography>{noOptionText}</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;

import React from "react";

//utils
import { percentager } from "../../../../../utils/helperFunctions";
import { giveDaysUptilNow } from "../../../../../utils/date-format";
import { MESSAGES } from "../../../../../utils/message";

// images
import Mailed from "../../../../../assets/images/orders/mailed.svg";
import Transit from "../../../../../assets/images/orders/transit.svg";
import Delivered from "../../../../../assets/images/orders/delivered.svg";
import Route from "../../../../../assets/images/orders/route.svg";
import Sender from "../../../../../assets/images/orders/sender.svg";
import Scanned from "../../../../../assets/images/orders/scanned.svg";
import NotScanned from "../../../../../assets/images/orders/notScanned.svg";
import Calender from "../../../../../assets/images/orders/calender.svg";

// styles
import "./styles.scss";
import { Box } from "@mui/material";



function DeliveryInfo({info, overview, stepper}) {
  
  const deliverableContacts =  overview?.deliverableContacts || 0;
  const totalContacts = overview?.totalContacts || 0;

  return (
    <Box className="delivery_info">
      <div className="card_outer">
        <div className="card">
          <img src={Mailed} alt="icon" />
          <p className="amount">{info.orderItemStatuses?.totalMailed || 0}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.MAILED}</span>
          <p className="percentage">{percentager(info.orderItemStatuses?.totalMailed || 0, deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={Transit} alt="icon" />
          <p className="amount">{info.orderItemStatuses['In Transit']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.IN_TRANSIT}</span>
          <p className="percentage">{percentager(info.orderItemStatuses['In Transit'], deliverableContacts )}%</p>
        </div>
        <div className="card">
          <img src={Delivered} alt="icon" />
          <p className="amount">{info.orderItemStatuses['Delivered']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.DELIVERED}</span>
          <p className="percentage">{percentager(info.orderItemStatuses['Delivered'], deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={Route} alt="icon" />
          <p className="amount">{info.orderItemStatuses['Re-Routed']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.RE_ROUTE}</span>
          <p className="percentage">{percentager(info.orderItemStatuses['Re-Routed'],  deliverableContacts )}%</p>
        </div>
        <div className="card">
          <img className="senderImg" src={Sender} alt="icon" />
          <p className="amount">{info.orderItemStatuses['Returned to Sender']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.RETURNED_TO_SENDER}</span>
          <p className="percentage">{percentager(info.orderItemStatuses['Returned to Sender'],  deliverableContacts)}%</p>
        </div>
      </div>
      <div  className="card_outer">
        <div className="card">
          <img src={Mailed} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.totalMailed || 0}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.MAILED}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.totalMailed || 0, deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={Scanned} alt="icon" />
          <p className="amount">{info.geoChart.scannedOrderItems}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.SCANNED}</span>
          <p className="percentage">{percentager(info.geoChart.scannedOrderItems, totalContacts)}%</p>
        </div>
        <div className="card">
          <img src={NotScanned} alt="icon" />
          <p className="amount">{info.geoChart.notScannedOrderItems}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.NOT_SCANNED}</span>
          <p className="percentage">{percentager(info.geoChart.notScannedOrderItems, totalContacts)}%</p>
        </div>
        <div className="card">
          <img src={Calender} alt="icon" />
          <p className="amount">{giveDaysUptilNow(stepper.createdAt)}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.DAY_ELAPSED}</span>
          <p className="percentage"></p>
        </div>
      </div>
    </Box>
  );
}

export default DeliveryInfo;
